import self from "../img/self.png"
import mock1 from "../img/mock1.png"

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];

export const info = {
    firstName: "Samuel",
    lastName: "Marek",
    initials: "S", 
    position: "a Software Developer",
    selfPortrait: self,
    gradient: `-webkit-linear-gradient(135deg, ${colors})`,
    baseColor: colors[0],
    miniBio: [ 
        {
            emoji: '🥤',
            text: 'fueled by Red Bull'
        },
        {
            emoji: '🌎',
            text: 'based in Austria'
        },
        {
            emoji: "💼",
            text: "Software Developer"
        },
        {
            emoji: "📧",
            text: "contact@samuelmarek.at"
        }
    ],
    socials: [
        {
            link: "https://github.com/x4m1",
            icon: "fa fa-github",
            label: 'github'
        },
        {
            link: "https://www.linkedin.com/in/samuel-marek-at",
            icon: "fa fa-linkedin",
            label: 'linkedin'
        }
    ],
    bio: "Hi I'm Samuel. I'm a passionate Developer who loves to create stuff and learn new things. I have completed the 'HTL Spengergasse' and I am currently studying at the 'TU Wien'. My favourite quote: 'Live as if you were to die tomorrow. Learn as if you were to live forever.'",
    skills:
        {
            proficientWith: ['C# | .Net Core | .Net MVC', , "Java | Spring Boot", 'REST | Graphql', 'React', 'git', "javascript"],
            exposedTo: ['nodejs', 'typescript', 'python', "docker", "web3"]
        }
    ,
    hobbies: [
        {
            label: 'Programming',
            emoji: '💻'
        },
        {
            label: 'Fitness',
            emoji: '🏋️‍♂️'
        },
        {
            label: 'cooking',
            emoji: '🌶'
        }
    ],
    portfolio: [ 
        /**{
            title: "Project 1",
            live: "https://paytonpierce.dev", 
            source: "https://github.com/paytonjewell", 
            image: mock1
        },**/
    ]
}